import { lazy } from 'react';
import routeNames from 'router/routeNames.json';
import PrivateRoute from 'components/common/PrivateRoute';
import { RedirectToMaster } from './components/RedirectToMaster';
import { RedirectMainUrl } from './components/RedirectMainUrl';
import { ReferralRedirect } from './components/ReferralRedirect';

const Alias = lazy(() => import('pages/Alias'));
const AliasLegacy = lazy(() => import('pages/AliasLegacy'));
const Checkout = lazy(() => import('pages/Checkout/Checkout'));
const CheckoutFailed = lazy(() => import('pages/Checkout/CheckoutFailed'));
const CheckoutLoad = lazy(() => import('pages/Checkout/CheckoutLoad'));
const Copyright = lazy(() => import('pages/Copyright/Copyright'));
const FreeTees = lazy(() => import('pages/FreeTees/FreeTees'));
const GiftCard = lazy(() => import('pages/Checkout/GiftCard'));
const Home = lazy(() => import('pages/Home'));
const Login = lazy(() => import('pages/Login'));
const Loyaltee = lazy(() => import('pages/Loyaltee'));
const NotFound = lazy(() => import('pages/NotFound'));
const Order = lazy(() => import('pages/Order'));
const OrderList = lazy(() => import('pages/OrderList'));
const Print = lazy(() => import('pages/Print'));
const Referral = lazy(() => import('pages/Referral'));
const Register = lazy(() => import('pages/Register'));
const ShopKids = lazy(() => import('pages/ShopKids'));
const ShopPrints = lazy(() => import('pages/ShopPrints'));
const ShopStickers = lazy(() => import('pages/ShopStickers'));
const ShopTees = lazy(() => import('pages/ShopTees'));
const Sticker = lazy(() => import('pages/Sticker'));
const SubmitDesign = lazy(() => import('pages/SubmitDesign'));
const SubmitDesignQuick = lazy(() => import('pages/SubmitDesignQuick'));
const SubmitDesignTerms = lazy(() => import('pages/SubmitDesignTerms'));
const Help = lazy(() => import('pages/Help/Help'));
const HelpContact = lazy(() => import('pages/Help/HelpContact'));
const Returns = lazy(() => import('pages/Help/Returns'));
const Tee = lazy(() => import('pages/Tee'));
const Kid = lazy(() => import('pages/Kid'));
const UserProfile = lazy(() => import('pages/UserProfile'));
const Votes = lazy(() => import('pages/Votes'));
const VotesDesign = lazy(() => import('pages/VotesDesign'));

export const routesConfig = [
  {
    path: routeNames.copyright,
    element: <Copyright />,
  },
  {
    path: routeNames.giftCards,
    element: <GiftCard />,
  },
  {
    path: `${routeNames.checkout}/*`,
    element: <Checkout />,
  },
  {
    path: `${routeNames.checkoutLoad}/:id`,
    element: <CheckoutLoad />,
  },
  {
    path: routeNames.checkoutFailed,
    element: <CheckoutFailed />,
  },
  {
    path: routeNames.home,
    element: <Home />,
  },
  {
    path: routeNames.lastChance,
    element: <Home lastChance />,
  },
  {
    path: routeNames.shopKids,
    element: <ShopKids />,
  },
  {
    path: `${routeNames.shopKids}/:slug`,
    element: <Kid />,
  },
  {
    path: routeNames.shopTees,
    element: <ShopTees />,
  },
  {
    path: `${routeNames.shopTees}/id/:id`,
    element: <Tee />,
  },
  {
    path: `${routeNames.shopTees}/:slug`,
    element: <Tee />,
  },
  {
    path: `${routeNames.shopTees}//:slug`,
    element: <Tee />,
  },
  {
    path: routeNames.shopStickers,
    element: <ShopStickers />,
  },
  {
    path: `${routeNames.shopStickers}/:slug`,
    element: <Sticker />,
  },
  {
    path: routeNames.shopPrints,
    element: <ShopPrints />,
  },
  {
    path: `${routeNames.shopPrints}/:slug`,
    element: <Print />,
  },
  {
    path: routeNames.freeTees,
    element: <FreeTees />,
  },
  {
    path: routeNames.userProfile,
    element: <UserProfile />,
  },
  {
    path: routeNames.userOrders,
    element: (
      <PrivateRoute>
        <OrderList />
      </PrivateRoute>
    ),
  },
  {
    path: `${routeNames.userOrders}/:orderId`,
    element: <Order />,
  },
  {
    path: `${routeNames.userOrders}/:orderId/*`,
    element: <Order />,
  },
  {
    path: routeNames.login,
    element: <Login />,
  },
  {
    path: routeNames.register,
    element: <Register />,
  },
  {
    path: routeNames.resetPassword,
    element: <RedirectToMaster />,
  },
  {
    path: `${routeNames.alias}/:aliasId`,
    element: <Alias />,
  },
  {
    path: '/pwa/*',
    element: <RedirectMainUrl />,
  },
  {
    path: routeNames.referral,
    element: <Referral />,
  },
  {
    path: `${routeNames.referralLink}/:referralCode`,
    element: <ReferralRedirect />,
  },
  {
    path: routeNames.designsVote,
    element: <Votes />,
  },
  {
    path: `${routeNames.designsVote}/:slug`,
    element: <VotesDesign />,
  },
  {
    path: routeNames.loyaltee,
    element: <Loyaltee />,
  },
  {
    path: routeNames.designsSubmit,
    element: <SubmitDesign />,
  },
  {
    path: `${routeNames.designsSubmit}/:designId`,
    element: <SubmitDesign />,
  },
  {
    path: `${routeNames.designsQuickSubmit}`,
    element: <SubmitDesignQuick />,
  },
  {
    path: routeNames.submitDesignTerms,
    element: <SubmitDesignTerms />,
  },
  {
    path: routeNames.help,
    element: <Help />,
  },
  {
    path: [
      routeNames.helpReturns,
      routeNames.helpReturnsEu,
      routeNames.helpReturnsIe,
      routeNames.helpReturnsNonEu,
    ],
    element: <Returns />,
  },
  {
    path: routeNames.helpContact,
    element: <HelpContact />,
  },
  /// Master Routes ///
  {
    path: routeNames.community,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.cookies,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.jobs,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.privacy,
    element: <RedirectToMaster />,
  },
  {
    path: `${routeNames.profile}/:username`,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.quickSubmit,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.teesVotesNewest,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.teesVotesPopular,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.teesVotesSelected,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.terms,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.userProfileEdit,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.shopSweaters,
    element: <RedirectToMaster />,
  },
  {
    path: routeNames.unsubscribeMarketing,
    element: <RedirectToMaster />,
  },
  {
    path: `${routeNames.unsubscribeNewsletter}/:email`,
    element: <RedirectToMaster />,
  },
  {
    path: '/:aliasId',
    element: <AliasLegacy />,
  },
  {
    path: routeNames.notFound,
    element: <NotFound />,
  },
];
